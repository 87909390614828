import styled from "@emotion/styled"
import React, { FC } from "react"
import Theme from "../lib/Theme"
import { rgba } from "../lib/utils"
import { CloudinaryImage } from "./CloudinaryImage"
import { Row } from "./Row"

interface Props {
  url?: string
  alt?: string
}

export const Hero: FC<Props> = ({ url, alt, children }) => (
  <Wrap>
    {url && (
      <Image>
        <CloudinaryImage publicId={url} alt={alt} width={1200} crop="scale" />
      </Image>
    )}
    <Overlay />
    <ContentRow>
      <Inner>{children}</Inner>
    </ContentRow>
  </Wrap>
)

const Wrap = styled.header`
  position: relative;
  background: ${Theme.Colors.purple.s100};
`

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 20%;
  right: 0;
  bottom: 0;
  z-index: 1;

  img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

const ContentRow = styled(Row)`
  position: relative;
  z-index: 3;

  color: #fff;
  padding: 8rem 1rem 5rem;

  ${Theme.Mediaquery.medium.min} {
    padding: 16rem 1rem 11rem;
  }
`

const Inner = styled.div`
  ${Theme.Mediaquery.medium.min} {
    width: 50%;
  }
`

const Overlay = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    90deg,
    ${Theme.Colors.purple.s100} 50%,
    ${rgba(Theme.Colors.purple.s100, 0.5)} 100%
  );

  ${Theme.Mediaquery.medium.min} {
    left: -6%;
    width: 60%;
    transform: skewX(-10deg);
  }
`
