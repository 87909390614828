import styled from "@emotion/styled"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Theme from "../lib/Theme"
import { HeadingBlock } from "./HeadingBlock"
import { Row } from "./Row"
import { Section } from "./Section"
import { TipCard, TipProps } from "./TipCard"

interface TipsSectionProps {
  items?: TipProps[]
  exclude?: string
}

export const TipsSection = ({ items, exclude, ...props }: TipsSectionProps) => {
  const result = useStaticQuery(graphql`
    query {
      kirby {
        allTip(limit: 4, sortBy: ["date", "DESC"]) {
          mainImage {
            url
            alt
          }
          excerpt
          title
          id
        }
      }
    }
  `)

  const tips = (items || result.kirby.allTip)
    .filter((item) => item.id !== exclude)
    .slice(0, 3)

  return (
    <Section {...props}>
      <Row>
        <HeadingBlock
          title="Tips voor een betere band met je paard"
          subtitle="Verrijk je kennis"
        >
          <p>
            Als paardenliefhebber ben je nooit uitgeleerd. Lees hier allerlei
            tips voor jou en je lievelingsdier.{" "}
            <AllPostsLink to="/tips/">Ontdek alle tips &raquo;</AllPostsLink>
          </p>
        </HeadingBlock>
        <Grid>
          {tips.map((tip) => (
            <TipCard key={tip.id} {...tip} />
          ))}
        </Grid>
      </Row>
    </Section>
  )
}

const Grid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin: 3rem 0 2rem 0;

  ${Theme.Mediaquery.medium.min} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const AllPostsLink = styled(Link)`
  color: ${Theme.Colors.pink.s100};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
