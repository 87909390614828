import { graphql } from "gatsby"
import React from "react"
import { BlocksRenderer } from "../components/BlocksRenderer"
import { CtaSection } from "../components/CtaSection"
import { Heading } from "../components/Heading"
import { Hero } from "../components/Hero"
import { Layout } from "../components/Layout"
import { ContentRow } from "../components/Row"
import { SEO } from "../components/SEO"
import { TipsSection } from "../components/TipsSection"
import "../lib/kirby.interface"

interface DetailTip extends Kirby.SEO, Kirby.CTA {
  id: string
  content: string
  title: string
  excerpt: string
  mainImage: { url: string; alt: string }
  tags: string[]
  date: string
}

const TipPage = ({ data }: Kirby.Data<{ detailTip: DetailTip }>) => {
  const content = data.kirby.detailTip

  return (
    <Layout>
      <SEO
        title={content.seoTitle || content.title}
        description={content.seoDescription || content.excerpt}
        imageUrl={content.mainImage.url}
      />

      <Hero url={content.mainImage.url} alt={content.mainImage.alt}>
        <Heading diap as="h1" size={40}>
          {content.title}
        </Heading>
      </Hero>

      <ContentRow as="article">
        <BlocksRenderer highlightFirst content={content.content} />
      </ContentRow>

      <CtaSection {...content} />

      <TipsSection exclude={content.id} />
    </Layout>
  )
}

export default TipPage

export const query = graphql`
  query TipDetail($id: ID!) {
    kirby {
      detailTip(id: $id) {
        id
        content
        excerpt
        ctaShow
        ctaText
        ctaTitle
        date
        mainImage {
          url
          alt
        }
        seoDescription
        seoTitle
        tags
        title
      }
    }
  }
`
